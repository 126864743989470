import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import {
  checkUserLoggedInFirstTime,
  getBannerCMSList,
  GetDashboardEnergyKPI,
  GetDashboardKPI,
  GetExpectedReturnValueByCreditDisposition,
  GetOrderAndUnitProcessed,
  GetSchedult_II_III_V_ChartData,
  getTransactionSummary,
  getUser,
  getUserSettings,
  setUserPermissions,
} from "../../../store/api/apiSlice";
import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Container, Button, Col, Row, Form, Table } from "react-bootstrap";
import { AuthenticatedTemplate } from "@azure/msal-react";
import AccountHierarchy from "../../shared/accountHierarchy/accountHierarchy";
import BarChart from "../../../components/charts/barChart";
import CurrencyConverter from "../../shared/currencyConverter/CurrencyConverter";
import Schedule_III_III_V_DetailModal from "./schedule_III_III_V_DetailModal";
import { useNavigate } from "react-router-dom";
import CreditsPerServiceFeeDollar from "./credits_Per_Service_Fee_Dollar";
import { useSelector } from "react-redux";
import {
  defaultNoRecordFound,
  expectedReturnDurationOptions,
  durationOptions,
  formateIds,
} from "../../../interface/comman.constant";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import {
  usePermission,
  ALL_PERMISSIONS,
} from "../../../helpers/hooks/usePermissions";
import PermissionOverlay from "../../shared/permissionOverlay";
import ContentLoader from 'react-content-loader';
import Slider from 'react-slick';

const KpiLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={600}
    height={65}
    viewBox="0 0 600 65"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: "100%" }}
  >
    <rect x="80" y="0" rx="3" ry="3" width="188" height="10" />
    <rect x="0" y="35" rx="3" ry="3" width="100" height="15" />
    <rect x="550" y="2" rx="8" ry="8" width="48" height="48" />
  </ContentLoader>
);

const OrderCountChartHeadingLoader = (props: any) => (
  <>
    <div className="d-flex justify-content-between">
      <div>
        <ContentLoader
          speed={2}
          width={300}
          height={10}
          viewBox="0 0 300 10"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          style={{ width: "100%" }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="300" height="10" />
        </ContentLoader>
      </div>

      <div>
        <ContentLoader
          speed={2}
          width={100}
          height={30}
          viewBox="0 0 100 30"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          style={{ width: "100%" }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100" height="30" />
        </ContentLoader>
      </div>
    </div>

    <div>
      <ContentLoader
        speed={2}
        width={620}
        height={360}
        viewBox="0 0 620 360"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="20" y="50" rx="0" ry="0" width="1" height="250" />
        <rect x="20" y="300" rx="0" ry="0" width="450" height="1" />
        <rect x="40" y="200" rx="0" ry="0" width="35" height="100" />
        <rect x="80" y="250" rx="0" ry="0" width="35" height="50" />
        <rect x="120" y="230" rx="0" ry="0" width="35" height="70" />
        <rect x="160" y="160" rx="0" ry="0" width="35" height="140" />
        <rect x="200" y="180" rx="0" ry="0" width="35" height="120" />
        <rect x="240" y="140" rx="0" ry="0" width="35" height="160" />
        <rect x="280" y="260" rx="0" ry="0" width="35" height="40" />
        <rect x="320" y="210" rx="0" ry="0" width="35" height="90" />
      </ContentLoader>
    </div>
  </>
);

const ExpectedHeadingLoader = (props: any) => (
  <>
    <div className="d-flex justify-content-between">
      <div>
        <ContentLoader
          speed={2}
          width={530}
          height={10}
          viewBox="0 0 530 10"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          style={{ width: "100%" }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="530" height="10" />
        </ContentLoader>
      </div>

      <div>
        <ContentLoader
          speed={2}
          width={100}
          height={30}
          viewBox="0 0 100 30"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          style={{ width: "100%" }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100" height="30" />
        </ContentLoader>
      </div>
    </div>
  </>
);

const ExpectedPieChartLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={630}
    height={300}
    viewBox="0 0 630 300"
    backgroundColor="#dee4f1"
    foregroundColor="#ecebeb"
    style={{ width: "100%" }}
  >
    <circle cx="160" cy="160" r="120" />
    <rect x="450" y="20" rx="0" ry="0" width="130" height="80" />
    <rect x="450" y="120" rx="0" ry="0" width="130" height="80" />
    <rect x="450" y="220" rx="0" ry="0" width="130" height="80" />
  </ContentLoader>
);

const ExpectedGridLoader = (props: any) => (
  <>
    <div>
      <ContentLoader
        speed={2}
        width={630}
        height={330}
        viewBox="0 0 630 330"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="0" y="0" rx="8" ry="8" width="630" height="330" />
      </ContentLoader>
    </div>
  </>
);

const Dashboard = () => {
  const permissions = usePermission(true);
  const journeySteps = [
    {
      target: ".my-first-step",
      placement: "center" as const,
      content:
        "The dashboard has quick links to the most important functionality on the portal as well as KPIs and charts/graphs to summarize your recent return history.",
    },
    {
      target: ".my-second-step",
      content:
        "Use this drop-down to select only the relevant sites for your visit.",
    },
    {
      target: ".my-third-step",
      content:
        "The dashboard includes quick links to help you efficiently navigate to the most important functions and reports in the portal.",
    },
    {
      target: ".my-third-first-step",
      content:
        "To begin an inventoried return (required for controlled product – optional for non-controls).",
    },
    {
      target: ".my-third-second-step",
      content:
        "To order kits for non-controlled returns, PharmaWaste, or Drug Take Back.",
    },
    {
      target: ".my-third-third-step",
      content: "Track a shipment sent to Pharma Logistics.",
    },
    {
      target: ".my-third-forth-step",
      content: "To view your last return in Order History.",
    },
    {
      target: ".my-fourth-prev-step",
      content: "The KPIs summarize key data regarding your return history.",
    },
    {
      target: ".my-fourth-step",
      content:
        "View of return data by credit disposition highlighting the most common reasons that product is found to be non-returnable.",
    },
    {
      target: ".my-fifth-step",
      content:
        "Live chat with our customer service team during business hours.",
    },
    {
      target: ".my-sixth-step",
      content: "Use the Navigation menus to quickly get where you need to go.",
    },
    {
      target: ".my-seventh-step",
      content: "Click to return to the dashboard from anywhere in the portal.",
    },
    {
      target: ".my-eight-step",
      content:
        "The Services menu directs you to where you can initiate a return or track shipments and upcoming returns.",
    },
    {
      target: ".my-nine-step",
      content:
        "The Compliance menu is a central location for all compliance-related reports.",
    },
    {
      target: ".my-ten-step",
      content:
        "The reporting menu lists the most popular reports as well as reports by category when clicking View All.",
    },
    {
      target: ".my-eleven-step",
      content:
        "The settings menu allows you to reset your account hierarchy anywhere within the portal, manage push reporting, and manage users for admins.",
    },
    {
      target: ".my-twelve-step",
      content:
        "The help link provides portal training resources and contact information for customer service.",
    },
    {
      target: ".my-thirteen-step",
      content:
        "Use these icons to access quick links, review notifications, and update your user profile anywhere in the portal.",
    },
  ];
  const [showProductTour, setShowProductTour] = useState(false);
  const dispatch = useAppDispatch();
  const [showHierarchy, setShowHierarchy] = useState(false);
  const [selectedOptionSchedule, setSelectedOptionSchedule] = useState("1");
  const [show_III_III_V_DetailModal, setShow_III_III_V_DetailModal] =
    useState(false);
  const [
    show_Credits_Per_Service_Fee_Dollar,
    setShow_Credits_Per_Service_Fee_Dollar,
  ] = useState(false);
  const [defaultDeaClass, setDefaultDeaClass] = useState("");
  const [durration, setDurration] = useState("");
  const [dashboardKPI, setDashboardKPI] = useState<any>();
  const [energyKPI, setEnergyKPI] = useState<any>();
  const [isLoading, setIsloading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [III_III_V_length, setIII_III_V_length] = useState(0);
  const [orderAndUnitsProcessedlength, setOrderAndUnitsProcessedlength] =
    useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // this is for EXPECTED RETURN VALUE BY CREDIT DISPOSITION
  const [nonReturnableData, setNonReturnableData] = useState<any>();
  Chart.register(CategoryScale);

  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const user =
    useSelector((state: any) => state?.auth?.userInfo?.data?.userDetail) || {};
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;

    const [allnews, setAllNews]: any = useState([
      {
          "id": "4e3e8381-7681-4072-82d6-da88b28e5741",
          "title": "Welcome to Pharma Logistics’ New Customer Portal",
          "description": `Experience the future of Pharma Logistics with our new customer
              portal, where <span>unmatched visibility</span>, 
              <span>simplified returns</span>, and 
              <span>actionable insights</span> come together to support your
              success.`,
          "no": 1
      }
  ]);
  var settings = {
    dots: true,
    infinite: allnews?.length > 1?true:false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000

  };

  useEffect(() => {
    const productTour = localStorage.getItem("product_tour");
    if (productTour === "true") {
      setShowProductTour(true);
    }
    setTimeout(() => {
      if (Object.keys(user).length === 0 && user.constructor === Object) {
        getPermissions();
        
      }

      localStorage.removeItem("product_tour");
    }, 4000);
    if (window.location.href.indexOf("login.aspx") > -1) {
      window.location.href = "/";
    }
  }, []);

  const getProfile = () => {
    dispatch(getUser()).then((result: any) => {
      setIsloading(false);
    });
  };

  const fetchUserSettings = () => {
    dispatch(getUserSettings({})).then((result: any) => {
      console.log(result);
      localStorage.setItem("userSettings", JSON.stringify(result?.payload?.data || {}));
    });
  };

  const getPermissions = () => {
    dispatch(setUserPermissions({ CustomerIds: "" })).then((result: any) => {
      getProfile();
      fetchUserSettings();
      localStorage.setItem(
        "permissions",
        JSON.stringify(result?.payload || {})
      );
      checkIsUserLoggedInFirstTime();
    });
  };
  const checkIsUserLoggedInFirstTime = () => {
    dispatch(checkUserLoggedInFirstTime()).then((result: any) => {
      if (result && result.payload && result.payload.data && !result.payload.data.isProductTourCompleted) {
        localStorage.setItem("product_tour", "true");
        setShowProductTour(true);
      }
    });
  };
  useEffect(() => {
    if (Object.keys(user).length > 0) {
      refreshCMS()
      loadKPIS();
      permissions?.["CountOfControlIIControlIII-VItems"] && loadScheduleData(1);
      permissions?.["ExpectedReturnValueByReturnStatus"] &&
        GetExpectedReturnValueByCreditDispositionLoding(2);
      permissions?.["OrdersItemsProcessed"] && GetOrderAndUnitProcessedData(1);
      transactionSummary();
      
    }
  }, [isLoading, refreshPage]);

  useEffect(() => {
    if (permissions?.["DrugLiquidationOrderHistory"] && !permissions?.["OrderHistory"]) {
      gotoPage("/reports/drug-liquidation-order-history");
    }
    
  }, [permissions?.["DrugLiquidationOrderHistory"], permissions?.["OrderHistory"]]);

  const [orderAndUnitsProcessed, setOrderAndUnitsProcessed] = useState({
    labels: [],
    datasets: [
      {
        type: "bar" as const,
        label: "Total Orders",
        backgroundColor: "#000351",
        data: [],
        borderColor: "#000351",
        fill: false,
        borderWidth: 2,
        pointBorderWidth: 3,
        borderRadius: 20,
        barThickness: 20,
        yAxisID: "percentage",
        order: 2,
      },
      {
        yAxisID: "y",
        type: "line" as const,
        label: "Total Units",
        borderColor: "#B30000",
        borderWidth: 3,
        pointBorderWidth: 2,
        backgroundColor: "#B30000",
        fill: false,
        data: [],
        borderRadius: 20,
        barThickness: 20,
        order: 1,
      },
    ],
  });
  const optionsOrderAndUnitsProcessed = {
    scales: {
      y: {
        title: {
          display: true,
          text: "Units",
        },
        type: "linear",
        position: "right",
        ticks: {
          beginAtZero: true,
          color: "black",
        },
        grid: { display: false },
      },
      percentage: {
        title: {
          display: true,
          text: "Orders",
        },
        type: "linear",
        position: "left",
        ticks: {
          beginAtZero: true,
          color: "black",
        },

        grid: { display: true },
      },
      x: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        onClick: null,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
        },
      },
    },
    onClick: (e: any, element: any) => {
      if (element.length > 0) {
        gotoPage("/reports/order-history");
      }
    },
  };

  const [dataDonut, setDataDonut] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: ["#000351", "#B30000", "#C2D500"],
        borderWidth: 0,
        barThickness: 35,
      },
    ],
  });
  const optionsDonute = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: "73%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 10,
        },
        callbacks: {
          label: function (tooltipData: any) {
            const labels = tooltipData.dataset.label.toString();
            const values = tooltipData.dataset.data[tooltipData.dataIndex];
            return `${labels}: ${values}%`;
          },
        },
      },
    },
  };

  const [dataSchedule, setDataSchedule] = useState({
    labels: [],
    datasets: [
      {
        label: "CII",
        data: [],
        backgroundColor: "#000351",
        borderWidth: 0,
        borderRadius: 20,
        barThickness: 20,
      },
      {
        label: "CIII-V",
        data: [],
        backgroundColor: "#B30000",
        borderWidth: 0,
        borderRadius: 20,
        barThickness: 20,
      },
    ],
  });

  const optionsSchedule = {
    onClick: (e: any, element: any) => {
      if (element.length > 0) {
        var ind = element[0].index;
        var datasetIndex = element[0].datasetIndex;
        setShow_III_III_V_DetailModal(true);
        setDefaultDeaClass(
          dataSchedule.datasets[datasetIndex].label === "CII"
            ? "Schedule_II"
            : "Schedule_III-V"
        );
        setDurration(dataSchedule.labels[ind]);
      }
    },
    // categoryPercentage: 0.8,
    //     barPercentage: 0.5,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        rotation: 1,
        labels: {
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
      },
    },
  };

  const updateChartData = (data1: any, data2: any, param: any) => {
    setDataSchedule({
      ...dataSchedule,
      datasets: [
        {
          ...dataSchedule.datasets[0],
          data: data1, // Update the data array with the new data
        },
        {
          ...dataSchedule.datasets[1],
          data: data2, // Update the data array with the new data
          backgroundColor: "rgba(179,0,0)",
          label: "CIII-V",
        },
      ],
    });
  };

  const updateScheduleLables = (k: any, v: any) =>
    setDataSchedule((prev) => ({ ...prev, [k]: v }));

  const removeDuplicates = (arr: any) => {
    let unique = arr.reduce(function (acc: any, curr: any) {
      if (!acc.includes(curr)) acc.push(curr);
      return acc;
    }, []);
    return unique;
  };

  const loadScheduleData = async (param?: any) => {
    try {
      let data = {
        chartDuration: parseInt(param),
        CustomerIds: formateIds(userAccounts),
      };
      dispatch(GetSchedult_II_III_V_ChartData(data)).then((result: any) => {
        let label: any = [];
        let chartDataSchedule_II: any = [];
        let chartDataSchedule_III: any = [];
        const yearWiseData: any = {};
        setIII_III_V_length(
          result?.payload?.data?.schedule_II_III_V_Chart?.length
        );
        if (
          result &&
          result.payload &&
          result.payload.data &&
          result.payload.isSuccess &&
          result?.payload?.data?.schedule_II_III_V_Chart?.length > 0
        ) {
          result.payload.data.schedule_II_III_V_Chart.forEach((item: any) => {
            const year = item.duration;
            if (!yearWiseData[year]) {
              yearWiseData[year] = [];
            }
            yearWiseData[year].push(item);
          });
        }

        for (const year in yearWiseData) {
          if (yearWiseData.hasOwnProperty(year)) {
            if (yearWiseData.hasOwnProperty(year)) {
              label.push(year);
            }
            if (yearWiseData[year].length == 1) {
              if (yearWiseData[year][0].key === "Schedule_II") {
                chartDataSchedule_II.push(yearWiseData[year][0].value);
                chartDataSchedule_III.push(0);
              } else if (yearWiseData[year][0].key === "Schedule_III-V") {
                chartDataSchedule_III.push(yearWiseData[year][0].value);
                chartDataSchedule_II.push(0);
              }
            }
            if (yearWiseData[year].length > 1) {
              yearWiseData[year].forEach(
                (element: { duration: any; key: any; value: any }) => {
                  if (element.key === "Schedule_II") {
                    chartDataSchedule_II.push(element.value);
                  }
                  if (element.key === "Schedule_III-V") {
                    chartDataSchedule_III.push(element.value);
                  }
                }
              );
            }
          }
        }
        updateChartData(chartDataSchedule_II, chartDataSchedule_III, param);
        updateScheduleLables("labels", removeDuplicates(label));
      });
    } catch (error) { }
  };

  const handleSelectSchedule = (event: any) => {
    setSelectedOptionSchedule(event.target.value);
    permissions?.["CountOfControlIIControlIII-VItems"] &&
      loadScheduleData(event.target.value);
  };

  /// this is for EXPECTED RETURN VALUE BY CREDIT DISPOSITION
  const handleSelectNonReturnableData = (event: any) => {
    GetExpectedReturnValueByCreditDispositionLoding(event.target.value);
  };

  const updateChartDonuteData = (data1: any) => {
    setDataDonut({
      ...dataDonut,
      datasets: [
        {
          ...dataDonut.datasets[0],
          data: data1, // Update the data array with the new data
        },
      ],
    });
  };

  const updateDonuteLable = (k: any, v: any) =>
    setDataDonut((prev) => ({ ...prev, [k]: v }));

  const GetExpectedReturnValueByCreditDispositionLoding = async (
    param?: any
  ) => {
    try {
      let data = {
        chartDuration: parseInt(param),
        CustomerIds: formateIds(userAccounts),
      };
      let label: any = [];
      let newData: any = [];

      dispatch(GetExpectedReturnValueByCreditDisposition(data)).then(
        (result: any) => {
          if (
            result &&
            result.payload &&
            result.payload.data &&
            result.payload.isSuccess
          ) {
            setNonReturnableData(result?.payload?.data);
            result.payload.data.expectedReturnValues.forEach((element: any) => {
              label.push(
                getLable(
                  element,
                  result?.payload?.data?.totalExpectedRetunERVCount
                )
              );
              newData.push(
                roundTo(
                  (
                    (element?.erv /
                      result?.payload?.data?.totalExpectedRetunERVCount) *
                    100
                  ).toFixed(3),
                  2
                )
              );
            });
          }
          updateChartDonuteData(newData);
          updateDonuteLable("labels", label);
        }
      );
    } catch (error) { }
  };

  const openHierarchy = () => {
    setShowHierarchy(true);
  };

  const loadKPIS = () => {
    let data = {
      CustomerIds: formateIds(userAccounts),
    };
    try {
      dispatch(GetDashboardKPI(data)).then((result: any) => {
        if (
          result &&
          result?.payload &&
          result?.payload?.data &&
          result?.payload?.isSuccess
        ) {
          setDashboardKPI(result?.payload?.data);
        }
      });

      if (permissions?.['EnergyKPI']) {

        dispatch(GetDashboardEnergyKPI(data)).then((result: any) => {
          if (
            result &&
            result?.payload &&
            result?.payload?.data &&
            result?.payload?.isSuccess
          ) {
            setEnergyKPI(result?.payload?.data);
          }
        });
      }
    } catch (error) { }
  };
  const gotoPage = (page: any) => {
    navigate(page);
  };
  const updateOrderAndUnitData = (data1: any, data2: any, param: any) => {
    setOrderAndUnitsProcessed({
      ...orderAndUnitsProcessed,
      datasets: [
        {
          ...orderAndUnitsProcessed.datasets[0],
          data: data1, // Update the data array with the new data
        },
        {
          ...orderAndUnitsProcessed.datasets[1],
          data: data2, // Update the data array with the new data

          label: "Total Units",
        },
      ],
    });
  };

  const updateOrderAndUnitsProcessedLable = (k: any, v: any) =>
    setOrderAndUnitsProcessed((prev) => ({ ...prev, [k]: v }));

  const GetOrderAndUnitProcessedData = (param?: any) => {
    let data = {
      chartDuration: parseInt(param),
      CustomerIds: formateIds(userAccounts),
    };
    let label: any = [];
    let totalOrders: any = [];
    let totalUnits: any = [];
    try {
      dispatch(GetOrderAndUnitProcessed(data)).then((result: any) => {
        if (
          result &&
          result?.payload &&
          result?.payload?.data &&
          result?.payload?.isSuccess
        ) {
          setOrderAndUnitsProcessedlength(
            result.payload.data.orderAndUnitProcessDetail.length
          );
          result.payload.data.orderAndUnitProcessDetail.forEach(
            (element: any) => {
              label.push(element.duration);
              totalOrders.push(element.totalOrders);
              totalUnits.push(element.totalUnits);
            }
          );
        }
        updateOrderAndUnitData(totalOrders, totalUnits, param);
        updateOrderAndUnitsProcessedLable("labels", label);
      });
    } catch (error) { }
  };

  const handleSelectOrderAndProcess = (event: any) => {
    GetOrderAndUnitProcessedData(event.target.value);
  };
  const transactionSummary = async () => {
    let data = {
      CustomerIds: formateIds(userAccounts),
    };
    dispatch(getTransactionSummary(data)).then((result: any) => {
      setTotalCount(result?.payload?.data?.totalCount);
      localStorage.setItem(
        "transactionCount",
        result?.payload?.data?.totalCount
      );
    });
  };
  const handleJoyrideCallback = (data: CallBackProps) => {
    if (data.action === "close" || data.action === "skip") {
      setShowProductTour(false);
      localStorage.removeItem("product_tour");
    }
  };

  function roundTo(num: any, precision: any) {
    const factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
  }

  const getTitle = (item: any) => {
    const myArray = item?.breakDown?.split(":");

    if (myArray?.length > 1) {
      return (
        "Returnable: " +
        roundTo(
          (
            (myArray[0] / nonReturnableData.totalExpectedRetunERVCount) *
            100
          ).toFixed(3),
          2
        ) +
        "%" +
        " , " +
        "Overstock: " +
        roundTo(
          (
            (myArray[1] / nonReturnableData.totalExpectedRetunERVCount) *
            100
          ).toFixed(3),
          2
        ) +
        "%"
      );
    }
  };

  const getLable = (item: any, total: any) => {
    let myArray = [];
    myArray = item?.breakDown?.split(":");

    if (myArray?.length > 1) {
      return (
        "Returnable: " +
        roundTo(((myArray[0] / total) * 100).toFixed(3), 2) +
        "%" +
        " , " +
        "Overstock: " +
        roundTo(((myArray[1] / total) * 100).toFixed(3), 2) +
        "%"
      );
    } else {
      return item?.creditDisposition;
    }
  };

  const refreshCMS = () => {
    dispatch(getBannerCMSList({})).then((res: any) => {
      setAllNews(res.payload.data);
    });
    
  }
  return (
    <AuthenticatedTemplate>
      <Container fluid>
        <Joyride
          run={showProductTour}
          steps={journeySteps}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
        />
        <div className="content_header d-flex justify-content-between align-items-center">
          <div className="header_left">
            <h5
              className={`content_header_title ${showProductTour ? "my-first-step" : ""
                }`}
              title="Dashboard"
            >
              Dashboard
            </h5>
          </div>
          {permissions?.["AccountHierarchySelection"] && (
            <div
              className={`header_right ${showProductTour ? "my-second-step" : ""
                }`}
            >
              <Button className="btn btn-white" onClick={() => openHierarchy()}>
                {" "}
                Select Account Hierarchy{" "}
                <i className="fa-solid fa-angle-down ms-4"></i>
              </Button>
            </div>
          )}
        </div>
      
        <div className="banner-db">
          <div className="left">
            
          <Slider {...settings} className="sliderbox-wrap dashboard-sliderbox">
          {allnews && allnews?.length > 0 && allnews.map((item: any, index: any) => (
                                          <div>
                                          <h2>{item?.title}</h2>
                                                <p  dangerouslySetInnerHTML={{__html: item.description}}></p>
                                          </div>
                                        )
                                      )}
     
      
    </Slider>
            
          </div>
          
          <div className={`right ${showProductTour ? "my-third-step" : ""}`}>
            {(permissions?.["DrugLiquidationOrderHistory"] && !permissions?.["OrderHistory"]) && (
              <div
                className={`thumbnail cursor_pointer`}
                onClick={() => gotoPage("/reports/drug-liquidation-order-history")}
              >
                <a>
                  <div className="icon">
                    <img
                      src="/assets/images/icon-order-history.svg"
                      alt=""
                    />
                  </div>
                  <div className="title">Order History</div>
                </a>
              </div>
            )}

            {((
              !permissions?.["LastReturn"] &&
              !(
                permissions?.["RequestATakebackKit"] ||
                permissions?.["RequestAPharmawasteKit"] ||
                permissions?.["RequestBoxAndShipSupplies"]
              ) &&
              !permissions?.["TrackAShipmentReport"] &&
              !permissions?.["StartAReturn"]
            ) &&
              permissions?.["RCSCP"]
            ) && (
                <div
                  className={`thumbnail cursor_pointer`}
                  onClick={() => gotoPage("/reports/destruction-manifests")}
                >
                  <a>
                    <div className="icon">
                      <img
                        src="/assets/images/icon-destruction-manifest.svg"
                        alt=""
                      />
                    </div>
                    <div className="title">Destruction Manifest</div>
                  </a>
                </div>
              )}

            <div>
              {permissions?.["StartAReturn"] && (
                <div
                  className={`thumbnail cursor_pointer  ${showProductTour ? "my-third-first-step" : ""
                    }`}
                  onClick={() => gotoPage("/services/start-Inventoried-return")}
                >
                  <a>
                    <div className="icon">
                      <img
                        src="/assets/images/icon-start-return-black.svg"
                        alt=""
                      />
                    </div>
                    <div className="title">Start an Inventoried Return</div>
                  </a>
                </div>
              )}
              {permissions?.["TrackAShipmentReport"] && (
                <div
                  className={`thumbnail cursor_pointer  ${showProductTour ? "my-third-third-step" : ""
                    }`}
                  onClick={() => gotoPage("/reports/track-shipment")}
                >
                  <a>
                    <div className="icon">
                      <img
                        src="/assets/images/icon-track-shipment-black.svg"
                        alt=""
                      />
                    </div>
                    <div className="title">Track a Shipment</div>
                  </a>
                </div>
              )}
            </div>

            <div>
              {(
                permissions?.["RequestATakebackKit"] ||
                permissions?.["RequestAPharmawasteKit"] ||
                permissions?.["RequestBoxAndShipSupplies"]
              ) && (
                  <div
                    className={`thumbnail cursor_pointer  ${showProductTour ? "my-third-second-step" : ""
                      }`}
                    onClick={() => {
                      gotoPage(
                        permissions?.["RequestAPharmawasteKit"] ?
                          "/services/request-pharma-kit"
                          :
                          permissions?.["RequestATakebackKit"] ?
                            '/services/request-take-back-kit'
                            : '/services/request-supplies'
                      )
                    }}
                  >
                    <a>
                      <div className="icon">
                        <img
                          src="/assets/images/icon-request-supplies.svg"
                          alt=""
                        />
                      </div>
                      <div className="title">Request Supplies</div>
                    </a>
                  </div>
                )}
              {permissions?.["LastReturn"] && (
                <div
                  className={`thumbnail cursor_pointer  ${showProductTour ? "my-third-forth-step" : ""
                    }`}
                  onClick={() =>
                    gotoPage(
                      totalCount === 1
                        ? "/reports/transaction-summary"
                        : "/reports/order-history"
                    )
                  }
                >
                  <a>
                    <div className="icon">
                      <img
                        src="/assets/images/icon-last-return-black.svg"
                        alt=""
                      />
                    </div>
                    <div className="title">Last Return</div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <>
          <div className="kpi-wrapper">
            <Row>
              <>
                <Col
                  xs={12}
                  md={4}
                  onClick={() => {
                    permissions?.["TotalCredits"] && gotoPage("/reports/disbursement-summary")
                  }}
                >
                  <PermissionOverlay
                    isGraph={false}
                    className="total-credit-bg"
                    title="TOTAL CREDITS"
                    permissions="TotalCredits"
                    loader={<KpiLoader />}
                  >
                    <div className="left">
                      <h3>
                        TOTAL <br className="d-lg-none" /> CREDITS
                      </h3>
                      <h4>
                        {dashboardKPI?.totalCredits != "" &&
                          dashboardKPI?.totalCredits != null
                          ? dashboardKPI?.totalCredits
                          : "$0.00"}
                      </h4>
                    </div>
                    <div className="right">
                      <div className="icon-bg1">
                        <i className="fa-solid fa-sack-dollar"></i>
                      </div>
                    </div>
                  </PermissionOverlay>
                </Col>
              </>

              <>
                <Col
                  xs={12}
                  md={4}
                  onClick={() => {
                    permissions?.["ServiceFeeGuarantee"] && gotoPage("/reports/servicefeeguarantees")
                  }}
                >
                  <PermissionOverlay
                    isGraph={false}
                    className="service-fee-bg"
                    title="Service Fee Guarantee"
                    permissions="ServiceFeeGuarantee"
                    loader={<KpiLoader />}
                  >
                    <div className="left">
                      <h3>
                        Service Fee <br className="d-lg-none" /> Guarantee
                      </h3>
                      <h4>
                        {dashboardKPI?.totalSFG != "" &&
                          dashboardKPI?.totalSFG != null
                          ? dashboardKPI?.totalSFG
                          : "$0.00"}
                      </h4>
                    </div>
                    <div className="right">
                      <div className="icon-bg2">
                        <i className="fa-solid fa-chart-line"></i>
                      </div>
                    </div>
                  </PermissionOverlay>
                </Col>
              </>
              {permissions?.['EnergyKPI'] && (
                <Col
                  xs={12}
                  md={4}
                >
                  <div className="kpi-box">
                    <div className="left">
                      <h3>
                        Waste to <br className="d-lg-none" /> Energy
                      </h3>
                      <h5>Pounds of waste cleanly converted to energy.</h5>
                      <h4>
                        {(energyKPI?.totalEnergy != "" && energyKPI?.totalEnergy != null) ?
                          energyKPI?.totalEnergy
                          :
                          "0.00"
                        }
                      </h4>
                    </div>
                    <div className="right">
                      <div className="icon-bg3">
                        <img src="/assets/images/icon-waste-energy.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              <>
                <Col
                  xs={12}
                  md={4}
                >
                  {/* <PermissionOverlay
                  isGraph={false}
                  className="credit-service-blur-bg"
                  title="Credits per Service Fee dollar"
                  permissions="CreditsPerServiceFee"
                  loader={<KpiLoader />}
                >
                  <div className="left">
                    <h3>
                      Credits per Service <br className="d-lg-none" /> Fee
                      dollar
                    </h3>
                    <h4>
                      {dashboardKPI?.creditPerServiceFeeDollar != "" &&
                        dashboardKPI?.creditPerServiceFeeDollar != null
                        ? dashboardKPI?.creditPerServiceFeeDollar
                        : "$0.00"}
                    </h4>
                  </div>
                  <div className="right">
                    <div className="icon-bg3">
                      <i className="fa-solid fa-chart-line"></i>
                    </div>
                  </div>
                </PermissionOverlay> */}
                </Col>
              </>
            </Row>
          </div>

          <div
            className={`graph-wrapper  ${showProductTour ? "my-fourth-prev-step" : ""
              }`}
          >
            <Row>
              <>
                <Col xs={12} lg={6} className="order-unit-chart">
                  <div
                    className={`content_body h-100 ${Object.keys(permissions).length > 1 &&
                      !permissions?.["OrdersItemsProcessed"]
                      ? "p-0"
                      : ""
                      }`}
                  >
                    <PermissionOverlay
                      isGraph={true}
                      className={"unit-processed-bg"}
                      title="Orders & Units Processed"
                      permissions="OrdersItemsProcessed"
                      loader={<OrderCountChartHeadingLoader />}
                    >
                      <div className="heading">
                        <h2>Orders & Units Processed</h2>
                        <div className="form_box">
                          <Form.Group controlId="exampleForm.ControlInput2">
                            <Form.Select
                              defaultValue={durationOptions[1].value}
                              aria-label="Default select example"
                              size="sm"
                              onChange={handleSelectOrderAndProcess}
                            >
                              {durationOptions.map((u, i) => {
                                return (
                                  <option key={i} value={u.value}>
                                    {u.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                      {orderAndUnitsProcessedlength > 0 && (
                        <BarChart
                          type="bar"
                          options={optionsOrderAndUnitsProcessed}
                          data={orderAndUnitsProcessed}
                        />
                      )}
                      {orderAndUnitsProcessedlength === 0 && (
                        <div className="no-records-wrapper mincardheight justify-content-center h-100">
                          <svg
                            width="48"
                            height="42"
                            viewBox="0 0 48 42"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                              fill="#3F475C"
                              fillOpacity="0.3"
                            />
                          </svg>
                          <span>{defaultNoRecordFound}</span>
                        </div>
                      )}
                    </PermissionOverlay>
                  </div>
                </Col>
              </>

              <>
                <Col xs={12} lg={6} className="count-schedule-chart">
                  <div
                    className={`content_body h-100  ${Object.keys(permissions).length > 1 &&
                      !permissions?.["CountOfControlIIControlIII-VItems"]
                      ? "p-0"
                      : ""
                      }`}
                  >
                    <PermissionOverlay
                      isGraph={true}
                      title="COUNT OF SCHEDULE II & SCHEDULE III-V"
                      className="clls-processed-bg"
                      permissions="CountOfControlIIControlIII-VItems"
                      loader={<OrderCountChartHeadingLoader />}
                    >
                      <div className="heading">
                        <h2>Count of Schedule II & Schedule III-V </h2>
                        <div className="form_box">
                          <Form.Group controlId="exampleForm.ControlInput2">
                            <Form.Select
                              defaultValue={durationOptions[1].value}
                              aria-label="Default select example"
                              size="sm"
                              onChange={handleSelectSchedule}
                            >
                              {durationOptions.map((u, i) => {
                                return (
                                  <option key={i} value={u.value}>
                                    {u.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                      {/* <Bar options={options} data={dataBar} /> */}
                      {III_III_V_length > 0 && (
                        <BarChart
                          id={"count-of-schedule"}
                          data={dataSchedule}
                          options={optionsSchedule}
                        />
                      )}
                      {III_III_V_length === 0 && (
                        <div className="no-records-wrapper mincardheight justify-content-center h-100">
                          <svg
                            width="48"
                            height="42"
                            viewBox="0 0 48 42"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                              fill="#3F475C"
                              fillOpacity="0.3"
                            />
                          </svg>
                          <span>{defaultNoRecordFound}</span>
                        </div>
                      )}
                    </PermissionOverlay>
                  </div>
                </Col>
              </>
            </Row>
          </div>

          {(
            (Object.keys(permissions).length <= 1) ||
            (Object.keys(permissions).length > 1 && permissions?.["ExpectedReturnValueByReturnStatus"])
          ) &&
            <div
              className={`graph-wrapper  ${showProductTour ? "my-fourth-step" : ""
                }`}
            >
              <Row>
                <Col md={12}>
                  <div className="content_body cradit_disposal ">
                    <div className="heading">
                      {/* <ExpectedHeadingLoader></ExpectedHeadingLoader> */}
                      <h2>Expected Return value by Credit Disposition</h2>
                      {permissions?.["ExpectedReturnValueByReturnStatus"] && (
                        <div className="form_box">
                          <Form.Group controlId="exampleForm.ControlInput2">
                            <Form.Select
                              defaultValue={expectedReturnDurationOptions[2].value}
                              aria-label="Default select example"
                              size="sm"
                              onChange={handleSelectNonReturnableData}
                            >
                              {expectedReturnDurationOptions.map((u, i) => {
                                return (
                                  <option key={i} value={u.value}>
                                    {u.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      )}
                    </div>

                    <Row>
                      <>
                        <Col xs={12} lg={8} md={12} xl={6}>
                          <div
                            className={`${nonReturnableData &&
                              nonReturnableData?.expectedReturnValues.length > 0
                              ? "expected-return-chart"
                              : ""
                              } ${Object.keys(permissions).length > 1 &&
                                !permissions?.["ExpectedReturnValueByReturnStatus"]
                                ? "p-0"
                                : ""
                              }`}
                          >
                            <PermissionOverlay
                              isGraph={true}
                              title=""
                              className="expected-return-graph-bg"
                              permissions="ExpectedReturnValueByReturnStatus"
                              loader={<ExpectedPieChartLoader />}
                            >
                              {nonReturnableData && nonReturnableData?.nonReturnableCategories.length > 0 && (
                                <Row className="align-items-center">
                                  <Col
                                    md={6}
                                    style={{ height: "230px" }}
                                    className="d-flex justify-content-center align-items-center mb-md-0 mb-2"
                                    onClick={() =>
                                      gotoPage("/reports/order-line-details")
                                    }
                                  >
                                    <Doughnut
                                      options={optionsDonute}
                                      data={dataDonut}
                                    />
                                  </Col>
                                  <Col md={6}>
                                    {nonReturnableData &&
                                      nonReturnableData?.expectedReturnValues
                                        .length > 0 &&
                                      nonReturnableData?.expectedReturnValues.map(
                                        (item: any, index: any) => (
                                          <Row key={index}>
                                            <Col
                                              xs={12}
                                              sm={10}
                                              xl={7}
                                              lg={10}
                                              md={10}
                                            >
                                              <div className="detail-box cursor_pointer">
                                                <div
                                                  onClick={() =>
                                                    gotoPage(
                                                      "/reports/order-line-details"
                                                    )
                                                  }
                                                >
                                                  <p className="mb-0">
                                                    <span
                                                      className={`chart-legend color${index}`}
                                                    ></span>
                                                    {item?.creditDisposition}
                                                  </p>
                                                  <h5>
                                                    {roundTo(
                                                      (
                                                        (item?.erv /
                                                          nonReturnableData.totalExpectedRetunERVCount) *
                                                        100
                                                      ).toFixed(3),
                                                      2
                                                    ) || 0}{" "}
                                                    %
                                                  </h5>
                                                  <h5>
                                                    {CurrencyConverter.format(
                                                      item?.erv
                                                    )}
                                                  </h5>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                  </Col>
                                </Row>
                              )}
                              {nonReturnableData && nonReturnableData?.nonReturnableCategories.length === 0 && (
                                <div className="no-records-wrapper mincardheight justify-content-center">
                                  <svg
                                    width="48"
                                    height="42"
                                    viewBox="0 0 48 42"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                                      fill="#3F475C"
                                      fillOpacity="0.3"
                                    />
                                  </svg>
                                  <span> {defaultNoRecordFound}</span>
                                </div>
                              )}
                            </PermissionOverlay>
                          </div>
                        </Col>
                      </>

                      <Col xs={12} lg={4} md={12} xl={6} className="mt-lg-0 mt-2">
                        <div className="detail-table">
                          <PermissionOverlay
                            isGraph={true}
                            title=""
                            className="expected-return-table-bg"
                            permissions="ExpectedReturnValueByReturnStatus"
                            loader={<ExpectedGridLoader />}
                          >
                            {nonReturnableData &&
                              nonReturnableData?.nonReturnableCategories.length >
                              0 && (
                                <Table responsive>
                                  <thead className="sticky-top">
                                    <tr>
                                      <th>Breakout of Non-Returnable</th>
                                      <th>% of Value</th>
                                      <th>Non-Returnable Value</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {nonReturnableData &&
                                      nonReturnableData?.nonReturnableCategories
                                        .length > 0 &&
                                      nonReturnableData?.nonReturnableCategories.map(
                                        (item: any, key: any) => (
                                          <tr key={key}>
                                            <td>{item?.category}</td>
                                            <td>{item?.percentage}</td>
                                            <td>{item?.erv}</td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </Table>
                              )}
                            {nonReturnableData?.nonReturnableCategories.length ===
                              0 && (
                                <div className="no-records-wrapper mincardheight justify-content-center">
                                  <svg
                                    width="48"
                                    height="42"
                                    viewBox="0 0 48 42"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                                      fill="#3F475C"
                                      fillOpacity="0.3"
                                    />
                                  </svg>
                                  <span>{defaultNoRecordFound}</span>
                                </div>
                              )}
                          </PermissionOverlay>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          }
        </>

        {showHierarchy && (
          <AccountHierarchy
            show={showHierarchy}
            handleClose={() => setShowHierarchy(false)}
          ></AccountHierarchy>
        )}
        {show_III_III_V_DetailModal && (
          <Schedule_III_III_V_DetailModal
            show={show_III_III_V_DetailModal}
            DurationType={selectedOptionSchedule}
            Duration={durration}
            DefaultDeaClass={defaultDeaClass}
            handleClose={() => setShow_III_III_V_DetailModal(false)}
          ></Schedule_III_III_V_DetailModal>
        )}
        {/* Below report is not in use right now */}
        {show_Credits_Per_Service_Fee_Dollar && (
          <CreditsPerServiceFeeDollar
            show={show_Credits_Per_Service_Fee_Dollar}
            handleClose={() => setShow_Credits_Per_Service_Fee_Dollar(false)}
          ></CreditsPerServiceFeeDollar>
        )}
      </Container>
      
    </AuthenticatedTemplate>
  );
};
export default Dashboard;
